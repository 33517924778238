import {onLCP, onFID, onCLS, onFCP, onINP, onTTFB } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: any) => void) => {
  if (onPerfEntry) {
    onLCP((metric) => {
      onPerfEntry(metric);
    });
    onFID((metric) => {
      onPerfEntry(metric);
    });
    onCLS((metric) => {
      onPerfEntry(metric);
    });
    onFCP((metric) => {
      onPerfEntry(metric);
    });
    onINP((metric) => {
      onPerfEntry(metric);
    });
    onTTFB((metric) => {
      onPerfEntry(metric);
    });
  }
}

export default reportWebVitals;