import { FC } from "react";
import { Box, Select, Option } from "@twilio-paste/core";
import { useTranslation } from "react-i18next";

interface LanguageSelectorProps {}

const LanguageSelector: FC<LanguageSelectorProps> = () => {
	const { i18n } = useTranslation();

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	return (
		<Box>
			<Select
				id="Language"
				onChange={(e) => changeLanguage(e.target.value)}
				name="language"
				value={i18n.language}
			>
				<Option value="en">🇺🇸 English</Option>
				<Option value="es">🇪🇸 Español</Option>
				<Option value="de">🇩🇪 Deutsch</Option>
				<Option value="fr">🇫🇷 Français</Option>
				<Option value="it">🇮🇹 Italiano</Option>
				<Option value="sv">🇸🇪 Svenska</Option>
			</Select>
		</Box>
	);
};

export default LanguageSelector;
