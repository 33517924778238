import {
  Booking,
  BookingApi,
  BookingStatus,
  Configuration,
  Participant,
  ParticipantApi,
  ParticipantDetail,
  RegistrationField
} from "@mmc/conferencing-booking-client";
import { Anchor } from "@twilio-paste/anchor";
import { Box } from "@twilio-paste/box";
import { Button } from "@twilio-paste/button";
import { Alert } from "@twilio-paste/core";
import { Checkbox } from "@twilio-paste/core/checkbox";
import { Option, Select } from "@twilio-paste/core/select";
import { Flex } from "@twilio-paste/flex";
import { Input } from "@twilio-paste/input";
import { InputBoxTypes } from "@twilio-paste/input-box/dist/types";
import { Label } from "@twilio-paste/label";
import { Spinner } from "@twilio-paste/spinner";
import { Text } from "@twilio-paste/text";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ACCOUNT_SID, BOOKING_API_URL, CONFERENCE_UI_URL, PRIVACY_STATEMENT_URL } from "../../constants";
import { useToken } from "../../hooks/useToken/useToken";
import { ConferenceHeader } from "./ConferenceHeader";
import { isWithinRegistrationWindow } from "../../core/isWithinRegistrationWindow";
import { BearerTokenMiddleware } from '@mmc/apiclient-auth-middleware';
import{ useSanitizedId} from "../../hooks/useSanitizedId/useSanitizedId";

let bookingApiClient: BookingApi,
    participantApiClient: ParticipantApi;

export const FormContainer: FC = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [booking, setBooking] = useState<Booking | undefined>();
  const [registrationData, setRegistrationData] = useState({} as any);
  const [participant, setParticipant] = useState<
    ParticipantDetail | undefined
  >();
  const { bookingId } = useParams();
  const [tokenResult] = useToken({ accountSid: ACCOUNT_SID });
  const [allowedToRegister, setAllowedToRegister] = useState(false);

  useEffect(() => {
    try {
      const { timeZone, locale } = Intl.DateTimeFormat().resolvedOptions();
      const language = i18n.language.substring(0, 2);

      setRegistrationData((prevData: any) => ({
        ...prevData,
        userLocale: locale,
        userTimeZone: timeZone,
        selectedLanguage: ["de", "es", "fr", "sv", "it"].includes(language)
          ? language
          : "en",
      }));
    } catch (err) {
      console.error(
        "Failed including user timezone/locale info with registration. Event information will be shown in UTC.",
        err
      );
    }
  }, [i18n.language]);

  const handleChange = (e: any) => {
    if (e.target.checked) {
      setRegistrationData({
        ...registrationData,
        [e.target.name]: e.target.checked,
      });
    } else {
      setRegistrationData({
        ...registrationData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!(bookingId && tokenResult)) {
          return;
        }

        setLoading(true);
        participantApiClient = new ParticipantApi(new Configuration({
          basePath: BOOKING_API_URL,
          middleware: [new BearerTokenMiddleware(tokenResult.token)]
        }));
        bookingApiClient = new BookingApi(new Configuration({
          basePath: BOOKING_API_URL,
          middleware: [new BearerTokenMiddleware(tokenResult.token)]
        }));
        const data: Booking = await bookingApiClient.bookingBookingIdGet({
          bookingId: bookingId
        });
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const [bufferDate] = yesterday.toJSON().split("T");

        const registrationForwardingUrl = (data as any).registrationForwardingUrl;

        if (data.status === BookingStatus.Canceled && registrationForwardingUrl) {
          // The booking has been cancelled, but we have a forwarding URL to redirect the user to
          window.location.href = registrationForwardingUrl;
          return;
        }

        if (
          (data.status === BookingStatus.Assigned ||
            data.status === BookingStatus.Accepted ||
            data.status === BookingStatus.Completed) &&
          parseInt(data.event.startDate.toJSON().split("T")[0].replace(/-/g, ""), 10) >=
            parseInt(bufferDate.replace(/-/g, ""), 10)
        ) {
          setBooking(data);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    })();
  }, [bookingId, tokenResult]);

  useEffect(() => {
    if (!booking) {
      return;
    }

    // Check if participant is allowed to register for this event

    // We have a 45 day registration window, but it's only relevant for conferences with
    // a start date on or after 1 October 2024.
    //
    // If start date earlier than that date, they can register.
    // If start date on or after that date, we need to be within 45 days of the start date.

    const featureIntroductionDate = new Date(Date.UTC(2024, 9, 1, 0, 0, 0)); // 1 October 2024
    const startDateAfterFeatureIntroduction =
      booking.event.startDate >= featureIntroductionDate;
    const withinRegistrationWindow = isWithinRegistrationWindow(
      booking.event.startDate
    );
    const allowedToRegister = !(
      startDateAfterFeatureIntroduction && !withinRegistrationWindow
    );

    setAllowedToRegister(allowedToRegister);
  }, [booking]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (!(bookingId && registrationData)) {
      return;
    }

    try {
      setLoading(true);
      const data: Participant | any = await participantApiClient.bookingBookingIdParticipantPost({
        bookingId: bookingId,
        participantDetail: {
          registrationData: registrationData
        }
      });

      if (data.pin) {
        let redirectUrl = `${CONFERENCE_UI_URL}/join/${data.participantId}?pwd=${data.pin}`;
        redirectUrl = appendSelectedLanguageToUrl(redirectUrl, data);
        window.location.href = redirectUrl;
        return;
      }

      setParticipant(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  function appendSelectedLanguageToUrl(
      redirectUrl: string,
      data: { registrationData?: { selectedLanguage?: string } }
  ): string {
    if (data?.registrationData?.selectedLanguage) {
      redirectUrl = `${redirectUrl}&lng=${data.registrationData.selectedLanguage}`;
    }
    return redirectUrl;
  }

  const sanitizedBookingId = useSanitizedId(bookingId, "booking");

  return (
    <Box width="100%" height="100%">
      {participant && (
        <Flex
          hAlignContent="center"
          vAlignContent="center"
          height="300px"
          vertical
        >
          <Text as="h2" marginBottom="space30">
            {t("form-page.thank-you-message")}
          </Text>
          <Text as="p">{t("form-page.confirmation-email-message")}</Text>
        </Flex>
      )}

      {!booking && loading && (
        <Flex hAlignContent="center" vAlignContent="center" height="300px">
          <Spinner size="sizeIcon90" decorative={false} title="Loading" />
        </Flex>
      )}

      {!(booking || loading) && (
        <Flex hAlignContent="center" vAlignContent="center" height="300px">
          <Text as="h2">{t("form-page.event-not-found-message")}</Text>
        </Flex>
      )}

      {booking && !participant && allowedToRegister && (
        <>
          <form onSubmit={onSubmit} style={{ width: "100%", height: "100%" }}>
            <Flex width="100%" vertical height="100%">
              <ConferenceHeader booking={booking} />

              <Box
                display="flex"
                flexDirection="column"
                rowGap="space50"
                width="100%"
                borderTopStyle="solid"
                borderTopWidth="borderWidth10"
                borderTopColor="colorBorderDecorative10Weaker"
                paddingY="space60"
                marginTop="space40"
              >
                {booking.registrationFields &&
                  booking.registrationFields.map((field: RegistrationField) => {
                    switch (field.type) {
                      case "text":
                        return (
                          <Box key={field.name} width="100%">
                            <Label
                              htmlFor={field.label}
                              required={field.isRequired}
                            >
                              {field.label}
                            </Label>
                            <Input
                              aria-describedby={field.label}
                              id={field.label}
                              type={
                                (field.inputValidation ||
                                  field.type) as InputBoxTypes
                              }
                              required={field.isRequired}
                              name={field.name}
                              maxLength={field.characterLimit}
                              onChange={handleChange}
                            />
                          </Box>
                        );
                      case "checkbox":
                        return (
                          <Box key={field.name} width="100%">
                            <Checkbox
                              checked={registrationData.name}
                              id={field.name}
                              name={field.name}
                              required={field.isRequired}
                              onChange={handleChange}
                            >
                              {field.label}
                            </Checkbox>
                          </Box>
                        );
                      case "select":
                        return (
                          field.options && (
                            <Box key={field.name} width="100%">
                              <Label
                                htmlFor={field.name}
                                required={field.isRequired}
                              >
                                {" "}
                                {field.label}
                              </Label>
                              <Select
                                name={field.name}
                                onChange={handleChange}
                                id={field.name}
                                defaultValue={field.defaultValue}
                                required={field.isRequired}
                              >
                                <Option value="">Select an option ...</Option>
                                {field.options.map(
                                  (option: any, index: any) => (
                                    <Option key={index} value={option.value}>
                                      {option.label}
                                    </Option>
                                  )
                                )}
                              </Select>
                            </Box>
                          )
                        );
                      default:
                        return <></>;
                    }
                  })}
              </Box>
              <Flex
                width="100%"
                hAlignContent="left"
                marginTop="space60"
                vertical
              >
                <Anchor
                  marginBottom="space60"
                  target="_top"
                  href={`${CONFERENCE_UI_URL}/enter/${sanitizedBookingId}`}
                >
                  {t("form-page.already-registered-link-text")}
                </Anchor>
                <Anchor
                  target="_blank"
                  showExternal
                  href={`${PRIVACY_STATEMENT_URL}`}
                >
                  {t("form-page.privacy-statement-text")}
                </Anchor>
              </Flex>
              <Flex width="100%" hAlignContent="right" marginTop="space60">
                <Button variant="primary" type="submit" loading={loading}>
                  {t("form-page.register-button")}
                </Button>
              </Flex>
            </Flex>
          </form>
        </>
      )}

      {booking && !participant && !allowedToRegister && (
        <>
          <Flex width="100%" vertical>
            <ConferenceHeader booking={booking} />

            <Box
              display="flex"
              flexDirection="column"
              rowGap="space50"
              width="100%"
              borderTopStyle="solid"
              borderTopWidth="borderWidth10"
              borderTopColor="colorBorderDecorative10Weaker"
              paddingY="space60"
              marginTop="space40"
            >
              <Alert variant="warning">
                <Text as="strong">{t("form-page.registration-closed")}</Text>
              </Alert>
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
};
