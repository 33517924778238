import { Booking } from "@mmc/conferencing-booking-client";
import { Box, Flex, Heading, Text } from "@twilio-paste/core";
import { FC } from "react";
import LanguageSelector from "../LanguageSelector";

interface ConferenceHeaderProps {
  booking: Booking;
}

export const ConferenceHeader: FC<ConferenceHeaderProps> = ({
  booking,
}: ConferenceHeaderProps) => (
  <>
    <Flex hAlignContent="between" width="100%">
      <Box>
        <Heading as="h4" variant="heading30" marginBottom="space0">
          {booking.event.name}
        </Heading>
        {booking.event?.startDate && (
          <Text as="p" marginBottom="space30">
            {new Date(booking.event.startDate).toLocaleString("UTC", {
              timeZoneName: "short",
            })}
          </Text>
        )}
      </Box>

      <LanguageSelector />
    </Flex>
  </>
);
