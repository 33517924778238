import NotifiedWhite from "./images/customer/notified-white.png";
import Favicon from "./images/customer/notified-favicon.png";

export const HOST_LOBBY_LOGO = NotifiedWhite;
export const HOST_BACKGROUND_FAVICON = Favicon;

// Merge config params into global Window interface
declare global {
  interface Window {
    TWILIO_ACCOUNT_SID: string;
    CONFERENCE_UI_URL: string;
    BOOKING_API_URL: string;
    EMAIL_NOREPLY_ADDRESS: string;
    HOST_TOKEN_PASSTHROUGH: string;
    PRIVACY_STATEMENT_URL: string;
    SENTRY_ENVIRONMENT: string;
    SENTRY_TARGET_REGISTRATION_UI: string;
    SENTRY_TRACE_SAMPLE_RATE: number;
    SENTRY_REPLAY_SESSION_SAMPLE_RATE: number;
    SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE: number;
    SENTRY_SAMPLE_RATE: number | undefined;
  }
}

// Environment
export const ACCOUNT_SID = window.TWILIO_ACCOUNT_SID;
export const CONFERENCE_UI_URL = window.CONFERENCE_UI_URL;
export const BOOKING_API_URL = window.BOOKING_API_URL;
export const EMAIL_NOREPLY_ADDRESS = window.EMAIL_NOREPLY_ADDRESS || 'noreply@media-server.com';
export const HOST_TOKEN_PASSTHROUGH = window.HOST_TOKEN_PASSTHROUGH;
export const PRIVACY_STATEMENT_URL = window.PRIVACY_STATEMENT_URL;

// Sentry config
export const SENTRY_ENVIRONMENT = window.SENTRY_ENVIRONMENT || 'local';
export const SENTRY_TARGET = Buffer.from(window.SENTRY_TARGET_REGISTRATION_UI, 'base64').toString('utf-8');
export const SENTRY_TRACE_SAMPLE_RATE = window.SENTRY_TRACE_SAMPLE_RATE;
export const SENTRY_REPLAY_SESSION_SAMPLE_RATE = window.SENTRY_REPLAY_SESSION_SAMPLE_RATE;
export const SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE = window.SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE;
export const SENTRY_SAMPLE_RATE = window.SENTRY_SAMPLE_RATE;
