import { FC } from "react";
import { Box } from "@twilio-paste/box";
import { Flex } from "@twilio-paste/flex";
import { Text } from "@twilio-paste/text";
import { useTranslation } from "react-i18next";
export const NotFound: FC = () => {
    const { t } = useTranslation();
    return (
        <Box width="100%" height="100%">
            <Flex hAlignContent="center" vAlignContent="center" height="300px">
                <Text as="h2">{t('form-page.event-not-found-message')}</Text>
            </Flex>
        </Box>
    );
};
