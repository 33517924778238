import { useEffect, useState } from "react";
import { Configuration, HostAuthApi, Token } from '@mmc/conferencing-booking-client';
import {BOOKING_API_URL, EMAIL_NOREPLY_ADDRESS, HOST_TOKEN_PASSTHROUGH} from '../../constants';

// No authentication middleware required for the host token generation endpoint
const hostAuthApiClient = new HostAuthApi(new Configuration({
    basePath: BOOKING_API_URL
}));

export const useToken = ({ accountSid }: { accountSid?: string }): [Token | undefined, boolean] => {
    const [result, setResult] = useState<Token>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            try {
                if(!accountSid)
                {
                    return;
                }

                setLoading(true);
                const token: Token = await hostAuthApiClient.tokenAccountSidPost({
                    accountSid: accountSid,
                    // Very bad: the token generation API endpoint validates that the "login details" match
                    // but it is otherwise UNPROTECTED!!!
                    // TODO: Find a better approach
                    loginDetail: {
                        email: EMAIL_NOREPLY_ADDRESS,
                        password: HOST_TOKEN_PASSTHROUGH
                    }
                });
                setResult(token);
            } catch (error) {
                setLoading(false);
            }
        })();
    }, [accountSid]);


    return [result, loading];
}
