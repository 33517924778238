import { FC, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Loading } from "../components/Loading";
import { NotFound } from "../components/NotFound/NotFound";
import { IntroContainer } from "../components/IntroContainer/IntroContainer";
import { FormContainer } from "../components/FormContainer/FormContainer";

export const Router: FC = () => {
  return (<Suspense fallback={<Loading />}>
    <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<IntroContainer />}>
            <Route path="register/:bookingId" element={<FormContainer />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
    </BrowserRouter>
  </Suspense>
  );
};